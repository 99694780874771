import React, { useReducer, useCallback, useMemo, useEffect } from "react";
import { Bookmark, Menu, X } from "lucide-react";

import projectsData from "../data/ProjectsData";

import { useIntersectionObserver } from "./hooks/useIntersectionObserver";
import { useResponsive } from "./hooks/useResponsive";

import { ProjectCard } from "./ProjectCard";

const initialState = {
  selectedProjectId: projectsData[0].id,
  isMenuOpen: false,
  isTransitioning: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SELECT_PROJECT":
      return { ...state, selectedProjectId: action.payload };
    case "TOGGLE_MENU":
      return { ...state, isMenuOpen: !state.isMenuOpen };
    case "SET_TRANSITIONING":
      return { ...state, isTransitioning: action.payload };
    default:
      return state;
  }
}

export const Projects = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isMobile } = useResponsive();

  const toggleMenu = useCallback(() => {
    dispatch({ type: "TOGGLE_MENU" });
  }, []);

  const scrollToProject = useCallback(
    (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      dispatch({ type: "SELECT_PROJECT", payload: id });
      if (isMobile) {
        toggleMenu();
      }
    },
    [isMobile, toggleMenu]
  );

  const onIntersect = useCallback((id) => {
    dispatch({ type: "SELECT_PROJECT", payload: id });
  }, []);

  useIntersectionObserver(
    projectsData.map((project) => project.id),
    onIntersect
  );

  useEffect(() => {
    dispatch({ type: "SET_TRANSITIONING", payload: true });
    const timer = setTimeout(() => {
      dispatch({ type: "SET_TRANSITIONING", payload: false });
    }, 300); // Match this with your transition duration

    return () => clearTimeout(timer);
  }, [isMobile]);

  const renderBookmarks = useMemo(
    () => (
      <nav
        className={`
      transition-all duration-300 ease-in-out
      ${
        isMobile
          ? "fixed inset-0 backdrop-blur-2xl z-50 flex flex-col items-center justify-center"
          : "fixed top-1/2 left-4 -translate-y-1/2 flex flex-col justify-center w-48 bg-opacity-50 bg-gray-800 backdrop-blur-2xl p-4 rounded-3xl shadow-lg z-20"
      }
      ${
        isMobile && !state.isMenuOpen
          ? "opacity-0 pointer-events-none transform -translate-x-1/2"
          : "opacity-100 transform translate-x-0"
      }
      ${state.isTransitioning ? "invisible" : "visible"}
    `}
      >
        {projectsData.map((project) => (
          <button
            key={project.id}
            className={`
            mb-4 cursor-pointer transition-all duration-300 ease-in-out flex items-center
            ${
              state.selectedProjectId === project.id
                ? "text-blue-400 scale-105"
                : "text-gray-400 hover:text-gray-200"
            }
            ${isMobile ? "text-2xl mb-8" : "text-sm"}
          `}
            onClick={() => scrollToProject(project.id)}
            aria-label={`Navigate to ${project.title}`}
          >
            <Bookmark size={isMobile ? 28 : 20} className="mr-2" />
            <span>{project.title}</span>
          </button>
        ))}
      </nav>
    ),
    [
      state.selectedProjectId,
      state.isMenuOpen,
      state.isTransitioning,
      isMobile,
      scrollToProject,
    ]
  );

  return (
    <div className="flex flex-col items-center min-h-screen bg-gradient-to-br from-gray-900 to-purple-900 text-gray-100">
      {renderBookmarks}
      <main className={`flex-1 max-w-[127vh] mt-24 mb-[37vh] p-4 ${!isMobile && "ml-48 p-8"}`}>
        {projectsData.map((project) => (
          <ProjectCard
            key={project.id}
            project={project}
            isSelected={state.selectedProjectId === project.id}
          />
        ))}
      </main>
      {isMobile && (
        <button
          onClick={toggleMenu}
          className="fixed bottom-8 right-8 p-2 bg-gray-900 rounded-full shadow-lg z-50"
          aria-label="Toggle menu"
        >
          {state.isMenuOpen ? <X size={32} /> : <Menu size={32} />}
        </button>
      )}
    </div>
  );
};

export default Projects;
