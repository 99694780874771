import React from "react";
import {
  Book,
  Briefcase,
  BookOpen,
  Flag,
  Music,
  Gamepad,
  Code,
  Laptop,
  Coffee,
  ChevronRight,
  Sparkles,
} from "lucide-react";

const EducationItem = ({ institution, degree, year, description, image }) => (
  <div className="bg-gray-800 bg-opacity-50 backdrop-blur-3xl transition-all duration-300 hover:shadow-xl hover:scale-105 shadow-lg rounded-lg p-6 mb-6 last:mb-0">
    <div className="flex flex-col sm:flex-row justify-between items-center">
      <div className="flex flex-col">
        <div className="flex items-center mb-2">
          <Book className="w-5 h-5 mr-2 text-blue-500" />
          <h3 className="text-lg font-semibold">{institution}</h3>
        </div>
        <p className="text-gray-200 font-medium">{degree}</p>
        {/* <p className="text-sm text-gray-500">{year}</p> */}
        {description && <p className="mt-2 text-gray-400">{description}</p>}
      </div>
      {image && (
        <img
          src={image}
          alt={institution}
          className="h-24 mt-4 sm:mt-0 rounded-lg object-fit shadow-lg"
        />
      )}
    </div>
  </div>
);

const EducationList = ({ educations }) => {
  return (
    <div className="space-y-6">
      {educations.map((edu, index) => (
        <EducationItem key={index} {...edu} />
      ))}
    </div>
  );
};

const EducationSection = ({ educations }) => (
  <section className="p-6 max-w-[80%] mx-auto">
    <h2 className="text-3xl font-bold mb-6 text-white">Education</h2>
    <EducationList educations={educations} />
  </section>
);

const Education = () => {
  const educations = [
    {
      institution: "Estaca - School of Engineering",
      degree: "Master of Engineering in Aerospace Engineering",
      year: "2022 - current",
      description: "Specialized in Aerospace Engineering and Space Systems",
      image:
        "https://upload.wikimedia.org/wikipedia/fr/0/05/ESTACA_Ecole_d%27ing%C3%A9nieurs_logo.svg",
    },
    {
      institution: "Lycée Victor Hugo",
      degree: "Preparatory Classes for Engineering Schools : MP2I",
      year: "2021 - 2022",
      description: "Specialized in Mathematics, Computer Science and Physics",
    },
    {
      institution: "Lycée Jean Rostand",
      degree: "Scientific Baccalaureate",
      year: "2018 - 2021",
      description: "Specialized in Mathematics and Physics",
    },
  ];

  return <EducationSection educations={educations} />;
};

const AboutMe = () => {
  const experiences = [
    {
      title: "Full-Stack Developer",
      company: "Alsace Digitale",
      period: "2023 - 2024",
      description:
        "Internship working as a Full-Stack developper creating a web app with several features using Laravel (PHP) and docker.",
    },
    // { title: 'Software Developer', company: 'InnovateSoft', period: '2018 - 2021', description: 'Developed scalable web applications using React and Node.js.' },
    // { title: 'Junior Developer', company: 'StartUp Inc.', period: '2016 - 2018', description: 'Contributed to mobile app development and learned industry best practices.' },
  ];

  const funFacts = [
    {
      icon: <Coffee size={24} />,
      text: "I never drink coffee, I prefer tea",
    },
    { icon: <Laptop size={24} />, text: "I built my first computer at age 11" },
    {
      icon: <Music size={24} />,
      text: "I have composed over 30 musical pieces in 4 genres",
    },
    { icon: <Gamepad size={24} />, text: "My first console was the NES" },
    {
      icon: <Flag size={24} />,
      text: "My most memorable trip was to Taïwan in 2024",
    },
    {
      icon: <Code size={24} />,
      text: "I have contributed to open-source projects",
    },
    { icon: <ChevronRight size={24} />, text: "I am right-handed" },
    { icon: <Gamepad size={24} />, text: "I'm extremely good at video games" },
  ];

  return (
    <div className="bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900 text-white min-h-screen font-sans overflow-hidden">
      <div className="container md:max-w-[177.78vh] mx-auto px-4 py-12">
      {/* Hero Section */}
      <div className="relative h-screen mt-12 md:mt-0 flex flex-col md:flex-row justify-evenly items-center">
        <img
          src="./profile.png"
          alt="Me ?"
          className="w-64 h-64 rounded-full object-cover shadow-lg text-center scale-x-[-1] hover:scale-x-[-1.15] hover:scale-y-[1.15] hover:shadow-xl shadow-lg transition-transform duration-300"
        />
        <div className="px-8 md:px-0 mt-8 md:mt-0">
          <h1 className="text-3xl font-bold mb-4">A brief introduction,</h1>
          <p className="text-xl text-justify mb-6 max-w-md ml-auto">
            I'm a passionate engineering student and tech enthusiast with a
            knack for building innovative solutions and entrepreneurial spirit.
            Starting at a young age, I've always been fascinated by the power of
            technology. I love to learn and explore new ideas, so my free time
            is used to work on personal projects.
          </p>
        </div>
      </div>

      {/* Current Focus */}
      <section className="p-6 min-h-screen flex flex-col justify-center max-w-[80%] mx-auto">
        <h2 className="text-3xl font-bold mb-6 text-white">Current Focus</h2>
        <div className="flex flex-col md:flex-row jutify-center items-center">
          <a
            href="https://www.youtube.com/watch?v=aircAruvnKk"
            className="min-w-max md:mr-8 mb-2 md:mb-0"
          >
            <img
              src="https://i.ytimg.com/vi/aircAruvnKk/maxresdefault.jpg"
              alt="Deep Learning Neural Networks"
              className="h-48 rounded-lg shadow-lg"
            />
          </a>
          <p className="text-2xl text-justify">
            Exploring the frontiers of Deep Learning Neural Networks and
            creating my own !
          </p>
        </div>
      </section>

      {/* Education */}
      <Education />

      {/* Work Experience */}
      <section className="p-6 min-h-screen flex flex-col justify-center max-w-[80%] mx-auto">
        <div>
          <h2 className="text-3xl font-bold mb-6 text-white">
            Work Experience
          </h2>
          <div className="space-y-8">
            {experiences.map((exp, index) => (
              <div
                key={index}
                className="flex flex-col md:flex-row justify-center items-start md:items-center relative"
              >
                <div className="flex flex-row items-center mr-4 mb-4 md:mb-0 relative">
                  <Briefcase size={24} className="text-blue-400 mr-2" />
                  <p className="text-xl font-semibold min-w-max">
                    {exp.period}
                  </p>
                </div>
                <div className="md:w-3/4 md:pl-12 pl-4 relative">
                  <div className="absolute left-0 top-0 w-1 h-full bg-blue-500"></div>
                  <h3 className="text-2xl font-bold mb-2">{exp.title}</h3>
                  <p className="text-xl text-blue-300 mb-2">{exp.company}</p>
                  <p className="text-lg text-justify">{exp.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <div className="py-24 relative overflow-hidden">
        <div className="container mx-auto px-4 relative z-10">
          <h2 className="text-3xl font-bold mb-12 ml-24">Work Experience</h2>
          <div className="space-y-8">
            {experiences.map((exp, index) => (
              <div
                key={index}
                className="flex flex-col md:flex-row justify-center items-start md:items-center relative"
              >
                <div className="flex flex-row items-center mr-4 mb-4 md:mb-0 relative">
                  <Briefcase size={24} className="text-blue-400 mr-2" />
                  <p className="text-xl font-semibold">{exp.period}</p>
                </div>
                <div className="md:w-3/4 md:pl-12 pl-4 relative">
                  <div className="absolute left-0 top-0 w-1 h-full bg-blue-500"></div>
                  <h3 className="text-2xl font-bold mb-2">{exp.title}</h3>
                  <p className="text-xl text-blue-300 mb-2">{exp.company}</p>
                  <p className="text-lg">{exp.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* Fun Facts */}
      <div className="container mx-auto px-4 py-12 sm:py-24">
      <h2 className="text-3xl sm:text-4xl font-bold mb-8 sm:mb-12 text-center sm:text-right sm:mr-4">Fun Facts</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {funFacts.map((fact, index) => (
          <div
            key={index}
            className="bg-gradient-to-br from-blue-800 to-purple-800 p-6 rounded-lg flex items-center space-x-4 transform hover:scale-105 transition-transform duration-300 shadow-lg"
          >
            <div className="text-blue-400 flex-shrink-0">
              {fact.icon || <Sparkles size={24} />}
            </div>
            <p className="text-lg text-white">{fact.text}</p>
          </div>
        ))}
      </div>
    </div>
      </div>
    </div>
  );
};

export default AboutMe;
