import { createContext, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Header from "./Header";
import Footer from "./Footer";

export const MenuContext = createContext({ isMenuOpen: false, toggleMenu: () => {} });

const Layout = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <MenuContext.Provider value={{ isMenuOpen, toggleMenu }}>
      <div style={{ backgroundColor: 'transparent', height: '100vh' }} className="bg-gray-900 text-white min-h-screen snap-y snap-mandatory overflow-y-scroll overflow-x-hidden">
        <Header location={location} />
        <AnimatePresence mode="wait">
          <Outlet key={location.pathname} />
        </AnimatePresence>
        <Footer />
      </div>
    </MenuContext.Provider>
  );
};

export default Layout;
