import React, { useRef, useState } from "react";
import { BookOpen, Users, BookText, ChevronDown } from "lucide-react";
import * as Icons from "react-icons/si";
// import Slider from 'react-infinite-logo-slider';

import TechBanner, { technologies } from "./TechBanner";

const TechIcon = ({ name }) => {
  const iconName =
    "Si" +
    name.charAt(0).toUpperCase() +
    name.slice(1).replace(/\./g, "dot").replace(" ", "").toLowerCase();
  const Icon = Icons[iconName] || Icons.SiReact; // Default to React icon if not found

  if (!Icon) {
    console.warn(`Icon not found for ${name}`);
    return null;
  }

  return <Icon className="w-full h-full" />;
};

const SkillsShowcase = () => {
  const skills = {
    languages: [
      { name: "French", level: "Native speaker", flag: "🇫🇷" },
      {
        name: "English",
        level: "Fluent with excellent writing skills, used everyday",
        flag: "🇺🇸",
      },
      {
        name: "German",
        level: "Basic comprehension, can have simple dialogs",
        flag: "🇩🇪",
      },
      {
        name: "Mandarin",
        level:
          "Intermediate level, currently learning in school. Getting comfortable with daily conversations",
        flag: "🇨🇳",
      },
      {
        name: "Russian",
        level: "Can read and understand basic phrases at the moment",
        flag: "🇷🇺",
      },
    ],
    academic: [
      { name: "Algorithms", level: "90%" },
      { name: "Data Structures", level: "85%" },
      { name: "Machine Learning", level: "50%" },
      { name: "Statistics", level: "75%" },
      { name: "Research Methods", level: "70%" },
      { name: "Aerodynamics", level: "80%" },
      { name: "Thermodynamics", level: "85%" },
      { name: "Fluid Mechanics", level: "80%" },
      { name: "Heat Transfer", level: "75%" },
      { name: "Electrical Circuits", level: "70%" },
    ],
    soft: [
      "Leadership",
      "Communication",
      "Problem-solving",
      "Teamwork",
      "Creativity",
      "Adaptability",
      "Time Management",
      "Critical Thinking",
      "Decision Making",
      "Negotiation",
    ],
  };

  const [activeTech, setActiveTech] = useState(false);
  const contentRef = useRef(null);

  const TechGrid = () => {
    return (
      <div className="md:p-6">
        {/* <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
          My Tech Stack
        </h2> */}
        <div className="grid gap-6 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-8">
          {technologies.map((tech) => (
            <div
              key={tech}
              className="flex flex-col items-center p-4 bg-gray-900 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl hover:scale-105"
            >
              {/* <Icon name={tech.icon} size={48} className="text-blue-500 mb-2" /> */}
              <TechIcon name={tech} />
              <span className="text-sm text-center font-medium text-gray-100">
                {tech}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const Expertise = () => (
    <div className="bg-gray-800 bg-opacity-0 md:bg-opacity-50 md:backdrop-blur-3xl md:shadow-lg rounded-lg overflow-hidden transition-all duration-300">
      <div className="px-2 py-8 md:px-10 md:py-12">
        <h3 className="text-2xl font-semibold text-white mb-6 text-center">
          My Area of Expertise
        </h3>
        <div className="flex flex-col md:flex-row items-center justify-center gap-8">
          <div className="w-32 h-32 flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur-3xl md:bg-opacity-0 md:backdrop-blur-none rounded-full p-4 transition-all duration-300">
            <div className="w-48 h-48">
              <TechIcon name="Cplusplus" />
            </div>
          </div>
          <div className="text-gray-400 text-justify text-xl leading-relaxed">
            I have been working with C++ for{" "}
            <span className="text-blue-400 font-medium">over 5 years now</span>.
            I have experience in and particularly enjoy developing{" "}
            <span className="text-blue-400 font-medium">
              high-performance applications.{" "}
            </span>
            I'm comfortable with{" "}
            <span className="text-blue-400 font-medium">modern C++</span>, using
            the STL and other libraries.
          </div>
        </div>
      </div>
    </div>
  );

  const Degree = () => (
    <div className="bg-gray-800 bg-opacity-0 md:bg-opacity-50 md:backdrop-blur-3xl md:shadow-lg rounded-lg overflow-hidden transition-all duration-300">
      <div className="px-2 py-8 md:px-10 md:py-12">
        <h3 className="text-2xl font-semibold text-white mb-6 text-center">
          Academic Skills
        </h3>
        <div className="flex flex-col md:flex-row items-center justify-center gap-8">
          <div className="text-gray-400 text-justify text-xl leading-relaxed bg-gray-800 backdrop-blur-3xl bg-opacity-50 p-6 md:p-0 md:bg-opacity-0 md:backdrop-blur-none rounded-lg transition-all duration-300">
            <span className="text-blue-400 font-medium">
              Aerospace Engineering
            </span>
            . This means I studied all the relevant subjects{" "}
            <span className="text-blue-400 font-medium">
              Fluid Mechanics, Thermodynamics, Electrical Circuits, Control
              Systems, etc
            </span>
            . I particularly enjoy{" "}
            <span className="text-blue-400 font-medium">
              Matlab, Simulink and Aerodynamics
            </span>
            .
          </div>
          <div className="w-32 h-32 flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur-3xl md:bg-opacity-0 md:backdrop-blur-none rounded-full p-4 transition-all duration-300">
            <div className="w-48 h-48">
              <BookText className="h-full w-full" />
              {/* <TechIcon name="Rocket" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const LanguageCards = () => (
    <div className="p-4">
      <div className="space-y-12">
        {skills.languages.map((lang, index) => (
          <div
            key={index}
            className={`flex justify-between items-center ${
              index % 2 === 0 ? "flex-row" : "flex-row-reverse"
            }`}
          >
            <div className={`text-8xl ${index % 2 === 0 ? "mr-4" : "ml-4"}`}>
              {lang.flag}
            </div>
            <div className="bg-gray-800 bg-opacity-50 backdrop-blur-3xl transition-all duration-300 shadow-lg rounded-lg p-3 flex-1">
              <h4 className="text-lg font-semibold text-white">{lang.name}</h4>
              <p className="text-gray-300">{lang.level}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const AcademicSkills = () => (
    <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold mb-4 text-white flex items-center">
        <BookOpen className="text-yellow-400 mr-2" size={24} />
        Academic Skills
      </h3>
      <div className="grid grid-cols-2 gap-4">
        {skills.academic.map((skill, index) => (
          <div
            key={index}
            className="bg-gray-700 p-3 rounded-lg transform transition-all hover:scale-105"
          >
            <h4 className="text-lg font-semibold text-white">{skill.name}</h4>
            {/* <div
              className="mt-2 h-1 bg-yellow-400 rounded-full"
              style={{ width: `${skill.level}` }}
            ></div> */}
          </div>
        ))}
      </div>
    </div>
  );

  const SoftSkills = () => (
    <div className="bg-gray-800 bg-opacity-50 backdrop-blur-2xl p-4 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold mb-4 text-white flex items-center">
        <Users className="text-pink-400 mr-2" size={24} />
        Soft Skills
      </h3>
      <div className="flex flex-wrap justify-center">
        {skills.soft.map((skill, index) => (
          <div
            key={index}
            className="m-2 px-4 py-2 bg-pink-800 text-white rounded-full text-m font-semibold transform transition-all hover:scale-110"
            style={{
              animationName: "float",
              animationDuration: `${Math.random() * 2 + 3}s`,
              animationIterationCount: "infinite",
              animationDirection: "alternate",
              animationTimingFunction: "ease-in-out",
            }}
          >
            {skill}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="w-full h-full bg-gradient-to-br from-gray-900 via-red-900 to-pink-900">
      <div className="px-6 rounded-xl max-w-5xl mx-auto">
        <div className="space-y-6">
          <section className="flex flex-col justify-center min-h-screen gap-6 snap-center">
            <Expertise />
          </section>
          <section className="flex flex-col justify-center snap-center">
            {/* <div className="w-1/2 mx-auto bg-gray-800 bg-opacity-50 backdrop-blur-3xl rounded-lg shadow-lg p-6">
              <p className="text-gray-300 text-xl text-justify">
                I have worked with a variety of technologies over the years. If
                you are interested in learning more about my tech stack, I have
                listed some of the technologies I feel most comfortable with
                below.
              </p>
            </div> */}
            <div
              className="flex flex-col justify-between items-center p-6 rounded-lg shadow-lg cursor-pointer overflow-hidden bg-gray-800 bg-opacity-50 backdrop-blur-3xl"
              onClick={() => setActiveTech(!activeTech)}
            >
              <div className="w-full flex flex-row justify-between items-center">
                <h2 className="text-3xl font-bold text-center text-gray-100">
                  My Tech Stack
                </h2>
                <ChevronDown size={48} />
              </div>
              <div
                ref={contentRef} // Assign ref to the content div
                className={`w-full transition-all duration-500 ease-in-out overflow-hidden`} // Increased duration and added ease
                style={{
                  maxHeight: activeTech
                    ? `${contentRef.current?.scrollHeight}px`
                    : "0px", // Use max-height for smoother transition
                  opacity: activeTech ? 1 : 0, // Fade in/out
                }}
              >
                <TechGrid />
              </div>
            </div>
          </section>
          {/* <TechGrid /> */}
          {/* <TechBanner /> */}
          {/* <AcademicSkills /> */}
          <section className="flex flex-col justify-center min-h-screen snap-center">
            <LanguageCards />
          </section>
          <section className="flex flex-col justify-center min-h-screen snap-center">
            <Degree />
          </section>
          <section className="flex flex-col justify-center min-h-screen snap-center">
            <SoftSkills />
          </section>
        </div>
      </div>
    </div>
  );
};

export default SkillsShowcase;
