import { link } from "framer-motion/client";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { Mail, GitHub, Linkedin, Twitter, Instagram, MapPin, Send } from "lucide-react";

// import Terminal from './Terminal';
// import MouseBlob from './MouseBlob';

const typedTexts = [
  "Innovator",
  1000,
  "Software Engineer",
  1000,
  "Game Designer",
  1000,
  "Entrepreneur",
  1000,
  "Tech Enthusiast",
  1000,
];

const ExploreCTA = () => {
  return null;
};

const Hero = () => (
  <div className="h-screen flex flex-col justify-center items-start px-[20vw]">
    <h1 className="text-5xl font-bold mb-4">
      Hi, I'm <span className="text-blue-400">Alexandre</span>
    </h1>
    <h2 className="text-3xl text-blue-400">
      <TypeAnimation sequence={typedTexts} speed={30} repeat={Infinity} />
    </h2>
    <ExploreCTA />
  </div>
);

const FullScreenSection = ({ title, content, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-80 z-50 overflow-hidden flex items-end justify-center">
      <div
        className={`w-full h-full bg-gray-800 p-8 rounded-t-3xl transition-all duration-500 ease-out transform ${
          isVisible
            ? "translate-y-0 opacity-100"
            : "translate-y-[10vh] opacity-0"
        }`}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-blue-400 transition-colors"
        >
          Close
        </button>
        <h2 className="text-4xl font-bold mb-6">{title}</h2>
        <div className="text-lg overflow-auto h-[calc(100%-4rem)]">
          {content}
        </div>
      </div>
    </div>
  );
};

const ExpandingThumbnail = ({ title, content, onClick, isMobile }) => {
  const [isExpanded, setIsExpanded] = useState(isMobile);
  // use lg breakpoint
  isMobile = window.innerWidth < 1024;

  useEffect(() => {
    setIsExpanded(isMobile);
  }, [isMobile]);


  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsExpanded(false);
    }
  };

  return (
    <div
      className={`${
        isExpanded ? "w-[95%] lg:w-[68%]" : "w-[25%]"
      } flex flex-col justify-between min-h-max lg:min-h-0 lg:h-[85%] bg-gray-800 p-4 transition-all duration-300 ease-in-out overflow-hidden cursor-pointer flex flex-col relative rounded-lg`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <h2
        className={`text-2xl font-bold whitespace-nowrap transform origin-center p-4`}
      >
        {title}
      </h2>
      <div
        className={`p-4 overflow-auto transition-all duration-500 ${
          isExpanded ? "opacity-100 h-full" : "opacity-0 h-0"
        }`}
      >
        {content}
      </div>
      <div
        className={`${
          isExpanded ? "opacity-100" : "opacity-0"
        } h-[10%] w-full flex justify-center mt-4 transition-all duration-500`}
      >
        Learn More
      </div>
    </div>
  );
};

const ThumbnailGrid = ({ isMobile }) => {
  const navigate = useNavigate();

  const sections = [
    {
      title: "About Me",
      link: "/about",
      content: (
        <div>
          <p className="mb-4 text-lg font-medium text-gray-700 dark:text-gray-300">
            Explore my background and the path that led me here
          </p>
          <h3 className="text-2xl font-bold mb-2 text-indigo-600 dark:text-indigo-400">
            Get to Know Me :
          </h3>
          <ul className="list-none text-lg">
            <li className="mb-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg p-2 text-white shadow-md">
              My passions and hobbies
            </li>
            <li className="mb-2 bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg p-2 text-white shadow-md">
              My educational journey
            </li>
            <li className="mb-2 bg-gradient-to-r from-pink-500 to-red-500 rounded-lg p-2 text-white shadow-md">
              Work Experiences
            </li>
            <li className="mb-2 bg-gradient-to-r from-red-500 to-orange-500 rounded-lg p-2 text-white shadow-md">
              Significant moments that shaped me
            </li>
            <li className="mb-2 bg-gradient-to-r from-orange-500 to-yellow-500 rounded-lg p-2 text-white shadow-md">
              Values and Beliefs I stand by
            </li>
            <li className="mb-2 bg-gradient-to-r from-yellow-500 to-green-500 rounded-lg p-2 text-white shadow-md">
              Languages I am learning
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Projects",
      link: "/projects",
      content: (
        <div>
          <p className="mb-4 text-lg font-medium text-gray-700 dark:text-gray-300">
            A curated collection of my creations, showcasing the my interests and the technologies I have worked with.
          </p>
          <h3 className="text-2xl font-bold mb-2 text-indigo-600 dark:text-indigo-400">
            Glimpse Within:
          </h3>
          <ul className="list-none text-lg">
            <li className="mb-2 bg-gradient-to-r from-blue-500 to-green-500 rounded-lg p-2 text-white shadow-md">
              A showcase of some of my projects.
            </li>
            <li className="mb-2 bg-gradient-to-r from-green-500 to-yellow-500 rounded-lg p-2 text-white shadow-md">
              The technologies and tools used.
            </li>
            <li className="mb-2 bg-gradient-to-r from-yellow-500 to-orange-500 rounded-lg p-2 text-white shadow-md">
              Interactive demonstrations where applicable.
            </li>
            <li className="mb-2 bg-gradient-to-r from-orange-500 to-red-500 rounded-lg p-2 text-white shadow-md">
              WIP: Detailed breakdown for each project.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Skills",
      link: "/skills",
      content: (
        <div>
          <p className="mb-4 text-lg font-medium text-gray-700 dark:text-gray-300">
            A non-exhaustive list of the skills I have acquired over the years.
          </p>
          <h3 className="text-2xl font-bold mb-2 text-indigo-600 dark:text-indigo-400">
            You'll Learn About:
          </h3>
          <ul className="list-none text-lg">
            <li className="mb-2 bg-gradient-to-r from-blue-500 to-green-500 rounded-lg p-2 text-white shadow-md">
              My Expertise in C++ and low-level programming
            </li>
            <li className="mb-2 bg-gradient-to-r from-green-500 to-yellow-500 rounded-lg p-2 text-white shadow-md">
              The Languages I speak or am learning
            </li>
            <li className="mb-2 bg-gradient-to-r from-yellow-500 to-orange-500 rounded-lg p-2 text-white shadow-md">
              Academic and Professional Skills
            </li>
            <li className="mb-2 bg-gradient-to-r from-orange-500 to-red-500 rounded-lg p-2 text-white shadow-md">
              Some soft-skills I have developed
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Contact",
      link: "/contact",
      content: (
        <div className="mt-8">
          <p className="mb-4 text-lg font-medium text-gray-700 dark:text-gray-300">
            Let's connect and collaborate - I'm eager to hear from you.
          </p>
          <h3 className="text-2xl font-bold mb-4 text-indigo-600 dark:text-indigo-400">
            Get in Touch:
          </h3>
          <ul className="list-none text-lg">
            <li className="flex flex-row items-center mb-2 bg-gradient-to-r from-blue-500 to-green-500 rounded-lg p-2 text-white shadow-md">
              <Send size={24} className="mr-2" />
              Leave a message
            </li>
            <li className="flex flex-row items-center mb-2 bg-gradient-to-r from-green-500 to-yellow-500 rounded-lg p-2 text-white shadow-md">
              <Mail size={24} className="mr-2" />
              Get my email
            </li>
            <li className="flex flex-row items-center mb-2 bg-gradient-to-r from-yellow-500 to-orange-500 rounded-lg p-2 text-white shadow-md">
              <Linkedin size={24} className="mr-2" />
              Connect on my socials
            </li>
            <li className="flex flex-row items-center mb-2 bg-gradient-to-r from-orange-500 to-red-500 rounded-lg p-2 text-white shadow-md">
              <MapPin size={24} className="mr-2" />
              Know my location
            </li>
          </ul>
          </div>
      ),
    },
  ];

  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="w-full lg:max-w-[177.78vh] lg:aspect-video p-4">
        <div className="h-full flex flex-col lg:flex-row justify-start lg:justify-evenly items-center gap-4">
          {sections.map((section) => (
            <ExpandingThumbnail
              key={section.title}
              title={section.title}
              content={section.content}
              onClick={() => navigate(section.link)}
              isMobile={isMobile}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const [hue, setHue] = useState(0);
  const [isMobile, setIsMobile] = useState(!useMediaQuery({ minWidth: 768 }));

  useEffect(() => {
    const interval = setInterval(() => {
      setHue((prevHue) => (prevHue + 1) % 360);
    }, 100); // Adjust speed here (lower is faster)

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      clearInterval(interval)
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <div className="bg-gray-900 text-white min-h-screen snap-y snap-mandatory overflow-y-scroll w-screen">
      <section
        id="home"
        className="snap-start"
        style={{
          background: `linear-gradient(to bottom right, hsl(${hue}, 100%, 15%), hsl(${
            hue + 60
          }, 100%, 15%), hsl(${hue + 120}, 100%, 15%))`, // Use HSL for smooth transitions
          transition: "background 0.5s ease", // Add a transition for smoothness
        }}
      >
        <Hero />
      </section>
      <section id="sections" className="md:snap-start">
        <ThumbnailGrid isMobile={isMobile} />
      </section>
    </div>
  );
};

export default Home;
