import * as Icons from "react-icons/si";

// TechIcon component
export const TechIcon = ({ name }) => {
  const iconName = `Si${name.charAt(0).toUpperCase()}${name
    .slice(1)
    .replace(/\./g, "dot")
    .replace(" ", "")
    .toLowerCase()}`;
  const Icon = Icons[iconName] || Icons.SiReact;

  return <Icon className="w-5 h-5 mr-1" />;
};
