import { useEffect, useRef } from "react";

export const useIntersectionObserver = (ids, onIntersect) => {
  const intersectingIds = useRef(new Set());

  useEffect(() => {
    const observers = ids.map((id) => {
      const element = document.getElementById(id);
      if (!element) return null;

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              intersectingIds.current.add(id);
            } else {
              intersectingIds.current.delete(id);
            }
          });

          // Select the element closest to the center of the screen
          if (intersectingIds.current.size > 0) {
            const windowCenter = window.innerHeight / 2.5;
            let closestElementId = null;
            let minDistance = Infinity;

            intersectingIds.current.forEach((id) => {
              const element = document.getElementById(id);
              const elementRect = element.getBoundingClientRect();
              const elementCenter = elementRect.top + elementRect.height / 2;
              const distance = Math.abs(windowCenter - elementCenter);

              if (distance < minDistance) {
                minDistance = distance;
                closestElementId = id;
              }
            });

            onIntersect(closestElementId);
          }
        },
        { threshold: 0 } // Consider even slight intersections
      );

      observer.observe(element);
      return observer;
    });

    return () => {
      observers.forEach((observer) => observer?.disconnect());
    };
  }, [ids, onIntersect]);
};