const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <p className="h-12 flex items-center justify-center text-gray-500 text-sm">
        &copy; {currentYear} Alexandre Roumane. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
