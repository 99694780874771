import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Analytics } from "@vercel/analytics/react";

import Layout from "./pages/Layout.js";
import Home from "./pages/Home.js";
import About from "./pages/Me.js";
import Projects from "./pages/Projects.js";
import Skills from "./pages/Skills.js";
import Contact from "./pages/Contact.js";

import PageTransition from "./pages/PageTransition.js";

const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/about", name: "About", Component: About },
  { path: "/projects", name: "Projects", Component: Projects },
  { path: "/skills", name: "Skills", Component: Skills },
  { path: "/contact", name: "Contact", Component: Contact },
];

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route element={<Layout />}>
          {routes.map(({ path, Component }) => (
            <Route
              path={path}
              element={
                <PageTransition>
                  <Component />
                </PageTransition>
              }
            />
          ))}
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  return (
    <Router>
      <Analytics />
      <AnimatedRoutes />
    </Router>
  );
};

export default App;
