import React, { useRef, useEffect } from 'react';
import * as Icons from 'react-icons/si';
// import SiCsh

const TechIcon = ({ name }) => {
    const iconName =
        "Si" +
        name.charAt(0).toUpperCase() +
        name.slice(1).replace(/\./g, "dot").toLowerCase();
    const Icon = Icons[iconName] || Icons.SiReact; // Default to React icon if not found

    if (!Icon) {
        console.warn(`Icon not found for ${name}`);
        return null;
    }

    return <Icon className="w-full h-full" />;
};

export const technologies = [
  'React',
  'Node.js',
  'Python',
  'JavaScript',
  'C',
  'Cplusplus',
  'Opengl',
  'Csharp',
  'HTML5',
  'CSS3',
  'Svelte',
  'Vercel',
  'Netlify',
  'GitHub',
  'MySQL',
  'Laravel',
  'PHP',
  'lua',
  'Assembly',
  'Ocaml',
  'Microsoft Office',
  'Microsoft Excel',
  'Microsoft Azure',
  'Google Cloud',
];

const TechBanner = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const scrollWidth = scrollElement.scrollWidth;
      let scrollPosition = 0;

      const scroll = () => {
        scrollPosition += 1;
        if (scrollPosition >= scrollWidth / 2) {
          scrollPosition = 0;
        }
        scrollElement.scrollLeft = scrollPosition;
        requestAnimationFrame(scroll);
      };

      requestAnimationFrame(scroll);
    }
  }, []);

  return (
    <div className="w-full overflow-hidden bg-gray-800 p-4 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold mb-4 text-white">I've worked with</h3>
      <div className="relative">
        <div
          ref={scrollRef}
          className="flex overflow-x-hidden"
          style={{ width: '100%' }}
        >
          {technologies.map((logo, index) => (
            <div
              key={index}
              className="flex-shrink-0 px-4"
              style={{ width: '150px' }}
            >
              {TechIcon({ name: logo })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechBanner;