const projectsData = [
  {
    id: 1,
    title: "This Website",
    description:
      "The website you are currently viewing. It is built with React, TailwindCSS, and deployed on Vercel.",
    image: "portfolio.png",
    technologies: [
      { name: "React" },
      { name: "TailwindCSS" },
      { name: "Vercel" },
    ],
  },
  {
    id: 2,
    title: "GuessThatGame",
    description:
      "A browser-based Game where players guess the name of the daily video game. The game is built with Svelte, HTML5, CSS3, and JavaScript.",
    image: "GTG.png",
    technologies: [
      { name: "Svelte" },
      { name: "HTML5" },
      { name: "CSS3" },
      { name: "JavaScript" },
      { name: "Netlify" },
    ],
  },
  {
    id: 3,
    title: "2D Fluid Simulation",
    description:
      "A real-time fluid simulation using advected velocities and a marker-and-cell grid. For performance optimization, parallel processing is used. Made with C++, OpenGL and GLSL.",
    image: "Fluid.png",
    technologies: [
      { name: "C++", alt: "Cplusplus" },
      { name: "OpenGL" },
      { name: "GLSL", alt: "Opengl" },
    ],
  },
  {
    id: 4,
    title: "Algorithmic Trader",
    description:
      "A trading bot that uses technical analysis to make buy and sell decisions. The analysis are based on Exponential Moving Average, Pivot Point Trading and custom arbitrage. The bot is built with Python, Google Cloud, TradingView, and Pine Script.",
    image: "Trading.png",
    technologies: [
      { name: "Python" },
      { name: "NumPy" },
      { name: "Google Cloud" },
      { name: "TradingView" },
      { name: "Pine Script" },
    ],
  },
  {
    id: 5,
    title: "XL3D Engine",
    description:
      "A custom 3D rendering engine built from scratch using C++ and OpenGL.",
    image: "XL3D.png",
    technologies: [
      { name: "C++", alt: "Cplusplus" },
      { name: "OpenGL" },
      { name: "GLSL", alt: "Opengl" },
    ],
  },
  {
    id: 6,
    title: "Athenaïs",
    description:
      "A prospecting tool that helps businesses find new clients. The tool uses web scraping to gather data from various sources and stores it in a database format chosen by the user (e.g. Excel, CSV, others). The data fetched is entirely customizable. This makes it easy to adapt to the user's needs. The tool is built with Python, Pandas, Google Cloud, Google Maps, Microsoft Azure, and Microsoft Excel.",
    image: "",
    technologies: [
      { name: "Python" },
      { name: "Pandas" },
      { name: "Google Cloud" },
      { name: "Google Maps" },
      { name: "Microsoft Azure" },
      { name: "Microsoft Excel" },
    ],
  },
  {
    id: 7,
    title: "Pathfinder Rover",
    description:
      "An autonomous rover that uses computer vision to navigate. The rover streams video to a web interface and can be controlled remotely. The rover software is built with C++, Arduino, PlatformIO, Esp32, and WebSockets.",
    image: "",
    technologies: [
      { name: "C++", alt: "Cplusplus" },
      { name: "Arduino" },
      { name: "PlatformIO" },
      { name: "Esp32" },
      { name: "WebSockets" },
      { name: "JavaScript" },
    ],
  },
  {
    id: 8,
    title: "More to come...",
    description:
      "I am always working on new projects. Stay tuned for more.",
    image: "",
    technologies: [
      { name: "C++", alt: "Cplusplus" },
      { name: "OpenGL" },
      { name: "GLSL", alt: "Opengl" },
      { name: "???" },
    ],
  },
];

export default projectsData;
