import React from "react";
import { TechIcon } from "./TechIcon";

// ProjectCard component
export const ProjectCard = React.memo(({ project, isSelected }) => (
  <div
    id={project.id}
    className={`mb-12 bg-gray-800 bg-opacity-70 backdrop-blur-2xl rounded-lg overflow-hidden shadow-lg transition-all duration-300 ease-in-out
        ${isSelected ? "ring-2 ring-blue-400 scale-100" : " scale-[0.98]"}`}
  >
    {project.image && (
      <div className={`transition-all duration-500 ease-in-out ${isSelected ? "" : "filter grayscale"}`}>
      <img
        src={project.image}
        alt={project.title}
        className="w-full max-h-[70vh] object-cover shadow-xl"
        />
      </div>
    )}
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-3 text-blue-400">{project.title}</h2>
      <p className={`text-gray-300 mb-4 transition-all duration-500 ease-in-out ${isSelected ? "" : "blur-sm"}`}>{project.description}</p>
      <div className={`flex flex-wrap gap-2 transition-all duration-500 ease-in-out ${isSelected ? "" : "blur-sm"}`}>
        {project.technologies.map((tech, index) => (
          <span
            key={index}
            className="flex items-center bg-gray-700 rounded-full px-3 py-1 text-sm font-semibold text-blue-300"
          >
            <TechIcon name={tech.alt || tech.name} />
            {tech.name}
          </span>
        ))}
      </div>
    </div>
  </div>
));
