import React, { useState } from 'react';
import { Send, Linkedin, Github, Twitter, Instagram, Mail, Phone, MapPin, Calendar } from 'lucide-react';

// Replace with your actual component imports or implementations
const Card = ({ children, className }) => (
  <div className={`rounded-lg shadow-md border border-gray-700 ${className}`}>
    {children}
  </div>
);

const CardContent = ({ children, className }) => (
  <div className={`p-4 ${className}`}>{children}</div>
);

const Button = ({ children, className, onClick, type }) => (
  <button
    type={type}
    onClick={onClick}
    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${className}`}
  >
    {children}
  </button>
);

const Input = ({ type, placeholder, value, onChange, className }) => (
  <input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className={`border border-gray-600 px-3 py-2 rounded-md w-full bg-gray-700 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400 ${className}`}
  />
);

const Textarea = ({ placeholder, value, onChange, className, rows }) => (
  <textarea
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className={`border border-gray-600 px-3 py-2 rounded-md w-full bg-gray-700 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400 ${className}`}
    rows={rows}
  />
);

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);  // For handling loading state
  const [submissionStatus, setSubmissionStatus] = useState(null); // For success/error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Show loading state
    setSubmissionStatus(null); // Clear any previous messages

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        setSubmissionStatus('success'); // Show success message
        setName('');
        setEmail('');
        setMessage('');
      } else {
        console.error('Error saving message:', response.status); 
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('Error saving message:', error);
      setSubmissionStatus('error'); // Show error message
    } finally {
      setIsSubmitting(false); // Hide loading state
    }
  };

  const socialLinks = [
    { icon: Linkedin, href: 'https://www.linkedin.com/in/alexandre-roumane-53a438257/', label: 'LinkedIn' },
    { icon: Github, href: '#', label: 'GitHub' },
    { icon: Twitter, href: '#', label: 'Twitter' },
    { icon: Instagram, href: '#', label: 'Instagram' },
  ];

  const contactMethods = [
    { icon: Mail, info: 'alexandre@roumane.net', label: 'Email' },
    { icon: Phone, info: 'On request', label: 'Phone' },
    { icon: MapPin, info: 'Paris/Strasbourg, France', label: 'Location' },
    { icon: Calendar, info: 'Schedule a meeting', label: 'Meeting' },
  ];

  return (
    <div className="min-h-screen mt-12 bg-gray-900 text-gray-100 p-8">
    <h1 className="text-4xl font-bold mb-8 text-center">Get in Touch</h1>

          
    <div className="grid md:grid-cols-2 gap-8">
        <Card className="bg-gray-800 border-blue-400">
          <CardContent className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Contact Form</h2>
            <form onSubmit={handleSubmit} method="POST" className="space-y-4">
              <Input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="bg-gray-700 border-blue-400"
              />
              <Input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-700 border-blue-400"
              />
              <Textarea
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="bg-gray-700 border-blue-400"
                rows={4}
              />
              <Button type="submit" disabled={isSubmitting} className="w-full bg-blue-400 hover:bg-blue-500 flex items-center justify-center disabled:bg-blue-200 disabled:cursor-not-allowed">
                {isSubmitting ? (
                  <span>Submitting...</span> // Loading state
                ) : (
                  <>
                    <span>Send Message</span>
                    <Send className="ml-2 h-4 w-4" />
                  </>
                )}
              </Button>


              {submissionStatus === 'success' && (
                <p className="text-green-500">Message sent successfully!</p>
              )}
              {submissionStatus === 'error' && (
                <p className="text-red-500">Error sending message. Please try again.</p>
              )}

            </form>
          </CardContent>
        </Card>

        <div className="space-y-8">
          <Card className="bg-gray-800 border-blue-400">
            <CardContent className="p-6">
              <h2 className="text-2xl font-semibold mb-4">Connect With Me</h2>
              <div className="grid grid-cols-2 gap-4">
                {socialLinks.map((link) => (
                  <a
                    key={link.label}
                    href={link.href}
                    onClick={(e) => {
                      if (link.href === '#') {
                        e.preventDefault();
                        alert('Sorry, I keep this account private.');
                      }
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center p-2 bg-gray-700 rounded-md hover:bg-blue-400 transition-colors"
                  >
                    <link.icon className="h-6 w-6 mr-2" />
                    {link.label}
                  </a>
                ))}
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gray-800 border-blue-400">
            <CardContent className="p-6">
              <h2 className="text-2xl font-semibold mb-4">Other Ways to Reach Me</h2>
              <ul className="space-y-4">
                {contactMethods.map((method) => (
                  <li key={method.label} className="flex items-center">
                    <method.icon className="h-6 w-6 mr-2 text-blue-400" />
                    <span>{method.info}</span>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;