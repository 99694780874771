import React, { useContext, useEffect, useState } from "react";
import { Mail, Home, CircleUser, FolderOpenDot, Sparkle, Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import { MenuContext } from "./Layout";
import { motion, AnimatePresence } from "framer-motion";

const links = [
  { to: "/", label: "Home", icon: <Home size={24} /> },
  { to: "/about", label: "About", icon: <CircleUser size={24} /> },
  { to: "/projects", label: "Projects", icon: <FolderOpenDot size={24} /> },
  { to: "/skills", label: "Skills", icon: <Sparkle size={24} /> },
  { to: "/contact", label: "Contact", icon: <Mail size={24} /> },
];

const Header = ({ location }) => {
  const { isMenuOpen, toggleMenu } = useContext(MenuContext);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return (
    <header className="fixed top-0 left-0 right-0 bg-gray-900 bg-opacity-0 backdrop-blur-lg z-50">
      <div className="flex justify-between items-center p-4 max-w-6xl mx-auto">
        <Link to={"/"} className="text-white text-xl font-bold">
          Alexandre Roumane
        </Link>
        {isMobile ? (
          <button onClick={toggleMenu} className="text-white">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        ) : (
          <nav className="flex">
            {links.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className="flex items-center ml-4 text-white hover:text-blue-400 transition-colors"
              >
                {link.icon}
                <span className="ml-2">{link.label}</span>
              </Link>
            ))}
          </nav>
        )}
      </div>
      <AnimatePresence>
        {isMobile && isMenuOpen && (
          <motion.nav
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="bg-gray-900 bg-opacity-0 backdrop-blur-lg"
          >
            {links.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className="flex items-center text-white hover:text-blue-400 transition-colors py-3 px-4"
                onClick={toggleMenu}
              >
                {link.icon}
                <span className="ml-2">{link.label}</span>
              </Link>
            ))}
          </motion.nav>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;

{/* {location.pathname === "/projects" && (
  <button 
    onClick={toggleMenu} 
    className={`md:hidden fixed bottom-4 right-4 z-50 p-2 rounded-full bg-gray-700 text-white order-2 md:order-none ${isMobile && isMenuOpen ? 'block' : 'block'}`} // Position fixed at bottom right on mobile
  >
    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
  </button>
)} */}